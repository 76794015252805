@import "hover.css/css/hover.css";
@font-face {
  font-family: "Frutiger";
  src: url("../font/PTSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Frutiger_bold";
  src: url("../font/PTSerif-Regular.ttf") format("truetype");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1, h2, h6 {
  text-transform: uppercase;
  font-weight: 400;
}

p, a {
  letter-spacing: 1px;
}

.maincolor {
  color: rgb(27, 75, 68);
}

.show {
  background-color: #ffffff;
}
@media screen and (max-width: 700px) {
  .show {
    padding: 10px;
    height: 100%;
  }
}
@media screen and (max-width: 480px) {
  .show {
    padding: 10px;
    height: 100%;
  }
}

body {
  overflow-x: hidden;
}

.mr-200 {
  margin: 100px auto;
}

.mt-200 {
  margin-top: 100px;
}

.mr-100 {
  margin: 100px auto;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin: 30px auto;
}

h6 {
  color: black;
  font-weight: 600;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.493);
  z-index: 2;
}

.navbar-toggler {
  background-color: white;
}

.read, .question {
  background-color: rgb(27, 75, 68);
  color: white;
  border: 2px solid rgb(27, 75, 68);
  text-decoration: none;
  padding: 10px 20px;
  transition: 0.5s ease;
}

.read:hover, .question {
  color: rgb(27, 75, 68);
  background-color: white;
}

.question:hover {
  background-color: rgb(27, 75, 68);
  color: white;
}

.flex-1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 67%;
}
.flex-1 p {
  line-height: 30px;
  font-size: 25px;
  font-weight: 100;
}

.flex-2 {
  justify-content: space-between;
}
.flex-2 > div {
  width: 48%;
}

.flex-3 {
  justify-content: space-evenly;
}
.flex-3 > div {
  width: 32%;
}

.flex-4 {
  justify-content: space-evenly;
}
.flex-4 > div {
  width: 24%;
}

.aline-item {
  align-items: center;
  min-height: 300px;
}
.aline-item p {
  color: rgb(0, 0, 0);
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}
.flex .wid-2 {
  width: 65%;
}
@media screen and (max-width: 700px) {
  .flex > div, .flex img, .flex .wid-2 {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .flex > div, .flex img, .flex .wid-2 {
    width: 100%;
  }
}
.flex .item {
  background-size: cover;
  position: relative;
  background-position: center;
  margin: 10px auto;
  overflow: hidden;
  height: 300px;
}
.flex .item .text {
  position: absolute;
  z-index: 3;
  top: 80%;
  color: white;
  display: flex;
  padding: 0px 22px;
  flex-direction: column;
  justify-content: space-evenly;
  transition: 0.5s ease-in;
}
.flex .item .text h2 {
  font-size: 20px;
  font-weight: 400;
}
.flex .item .text p {
  transform: rotateX(90deg);
  transition: 0.6s ease-in;
  font-size: 12px;
  opacity: 0.7;
}
.flex .item h1 {
  color: rgb(27, 75, 68);
  font-size: 30px;
  margin-bottom: 20px;
}
.flex .item .shadow {
  background-color: rgb(39, 90, 83);
  opacity: 0;
  transition: 0.5s ease-in;
}
.flex .item:hover .shadow {
  background-color: rgb(39, 90, 83);
  opacity: 1;
}
.flex .item:hover .text {
  top: 20%;
}
.flex .item:hover .text p {
  transform: rotateX(0deg);
}

.header {
  width: 100%;
  text-align: center;
  margin: 7px auto;
}
.header h1 {
  color: rgb(27, 75, 68);
  font-size: 40px;
  margin: 20px auto;
  font-weight: 900;
  text-transform: uppercase;
}

.social {
  position: absolute;
  color: #ffffff;
  z-index: 3;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 10%;
  right: 10%;
}
.social h1 {
  font-weight: bolder;
  font-size: 60px;
  text-align: center;
  color: rgb(17, 41, 38);
  text-transform: capitalize;
}
.social p {
  text-align: center;
}
.social a {
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  text-transform: capitalize;
  text-align: center;
}
.social a svg {
  background-color: #ffffff;
  color: rgb(39, 90, 83);
  padding: 10px;
  border-radius: 50%;
}
@media screen and (min-width: 56.25em) {
  .social {
    left: 20%;
    width: 60%;
    right: 20%;
  }
  .social h1 {
    font-size: 35px;
  }
  .social p {
    font-size: 20px;
  }
}
@media screen and (max-width: 700px) {
  .social {
    left: 10%;
    right: 10%;
  }
  .social h1 {
    font-size: 40px;
  }
  .social p {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .social {
    left: 10%;
    right: 10%;
  }
  .social h1 {
    font-size: 30px;
  }
  .social p {
    font-size: 12px;
  }
}

.navbar {
  position: absolute;
  z-index: 150000000000000;
  top: 30px;
  width: 100%;
}
.navbar .dropdown button::after {
  font-size: large;
}
.navbar .dropdown button:hover {
  background-color: transparent;
}
.navbar .dropdown .btn-check:checked + .btn, .navbar .dropdown .btn.active, .navbar .dropdown .btn.show, .navbar .dropdown .btn:first-child:active, .navbar .dropdown :not(.btn-check) + .btn:active {
  background-color: transparent;
}
.navbar .nav-link {
  color: #ffffff;
  transition: 0.5s ease;
  font-size: 13px;
  margin: 0px 5px;
}
.navbar .nav-link:hover {
  color: rgb(39, 90, 83);
}
@media screen and (max-width: 700px) {
  .navbar .nav-link {
    color: rgb(27, 75, 68);
  }
}
@media screen and (max-width: 480px) {
  .navbar .nav-link {
    color: rgb(27, 75, 68);
  }
}
.navbar img {
  width: 141px;
  height: 50px;
}
@media screen and (max-width: 700px) {
  .navbar {
    top: 0px;
  }
}
@media screen and (max-width: 480px) {
  .navbar {
    top: 0px;
  }
}

.sticky {
  position: fixed;
  background-color: #ffffff;
  top: 0px;
}
.sticky .nav-link {
  color: rgb(27, 75, 68);
}
.sticky .nav-link:hover {
  color: #E2963C;
  opacity: 0.8;
}
.HomeBanner {
  width: 100%;
  height: 700px;
  position: relative;
}
.HomeBanner video {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.img, .img2 {
  background-position: 0% 25%;
  box-shadow: 0px 0px 5px #d1d1d1, -2px -2px 30px #d1d1d1;
  background-size: cover;
}

.img2 {
  background-position: 0% 42%;
}

.abouttext {
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.abouttext .img2 {
  height: 50%;
  margin-bottom: 5%;
}
.abouttext h1 {
  color: rgb(27, 75, 68);
  font-weight: 400;
}
.abouttext p {
  color: rgb(0, 0, 0);
  margin: 20px auto;
  font-size: 15px;
}
.abouttext a {
  text-decoration: none;
}

.swiper {
  margin-top: 30px;
}
.swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper img {
  width: 150px;
  height: 80px;
}

.from {
  text-align: center;
  margin: 100px auto;
  /* active state */
  /* ANIMATIONS ================ */
  /* LABEL ======================================= */
  /* active state */
  /* BOTTOM BARS ================================= */
  /* active state */
}
.from .group {
  position: relative;
  margin: 45px auto;
  width: 60%;
}
@media screen and (max-width: 700px) {
  .from .group {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .from .group {
    width: 100%;
  }
}
.from .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.from input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}
@keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}
.from input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
.from input:focus {
  outline: none;
}
.from label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.from input:focus ~ label, .from input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #E2963C;
}
.from .bar {
  position: relative;
  display: block;
  width: 100%;
}
.from .bar:before, .from .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #E2963C;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.from .bar:before {
  left: 50%;
}
.from .bar:after {
  right: 50%;
}
.from input:focus ~ .bar:before, .from input:focus ~ .bar:after {
  width: 50%;
}
@media (min-width: 400px) and (max-width: 700px) {
  .from .group {
    width: 100%;
  }
  .from .bar {
    width: 100%;
  }
  .from input {
    width: 100%;
  }
}

.send {
  background-color: rgb(27, 75, 68);
  padding: 15px 50px;
  border: 0px;
  transition: 0.5s;
}
.send:hover {
  background-color: #E2963C;
}

.Footer {
  overflow: hidden;
  color: #ffffff;
  padding: 20px;
}
.Footer p {
  color: black;
  margin: 20px auto;
  opacity: 0.8;
  font-size: 14px;
}
.Footer .container {
  margin: 40px auto;
}
.Footer a {
  font-size: 15px;
  text-decoration: none;
  color: black;
  transition: 0.4s ease-in-out;
}
.Footer a:hover {
  color: #E2963C;
}
.Footer .socialfooter a {
  font-size: 25px;
  width: 25px;
  margin-right: 20px;
  text-decoration: none;
  color: #E2963C;
  transition: 0.5s ease-in-out;
}
.Footer .socialfooter a:hover {
  color: rgb(27, 75, 68);
}
.Footer .Home {
  text-decoration: none;
}
.Footer img {
  height: 50px;
  width: 141px;
}
@media screen and (max-width: 700px) {
  .Footer img {
    height: 50px;
    width: 115.5 px;
  }
}
@media screen and (max-width: 480px) {
  .Footer img {
    height: 50px;
    width: 170px;
  }
}
.Footer h1 {
  font-size: 20px;
  text-transform: uppercase;
  color: rgb(27, 75, 68);
}

.banner {
  background-position: center;
  background-size: cover;
  height: 600px;
  position: relative;
}
.banner .section {
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 10%;
  left: 5%;
  right: 5%;
  width: 50%;
  z-index: 3;
}
.banner .section h4 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 900;
}
.banner .section p {
  color: rgba(255, 255, 255, 0.74);
  font-size: 17px;
}
.banner .section .text {
  color: white;
  margin: 5px 5px 10px 5px;
  color: #E2963C;
  font-weight: 700;
}
.banner .section a {
  color: rgba(255, 255, 255, 0.514);
  transition: 0.5s ease;
  text-decoration: none;
  font-weight: 700;
}
.banner .section a:hover {
  color: white;
}
@media screen and (max-width: 700px) {
  .banner .section {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .banner .section {
    width: 100%;
  }
}

.swiper-button-next, .swiper-button-prev {
  background-color: #E2963C !important;
  color: white !important;
  padding: 25px !important;
  border-radius: 50% !important;
}
.swiper-button-next::after, .swiper-button-prev::after {
  text-align: center !important;
  font-size: 20px !important;
}

.swiper-pagination-bullet-active {
  background-color: #E2963C !important;
}

.data img {
  width: 100%;
}

.recode {
  justify-content: space-between;
}

.cut .img2 {
  margin-top: 10%;
}

.homecut {
  min-height: 400px;
}

.homecut:nth-child(odd) .abouttext {
  order: 1;
}
.homecut:nth-child(odd) .img {
  order: 2;
}

.homecut:nth-child(even) .abouttext {
  order: 2;
}
.homecut:nth-child(even) .img {
  order: 1;
}

.carousel-control-prev, .carousel-control-next, .carousel-caption, .carousel-indicators {
  z-index: 5;
}

.events .carousel-caption {
  z-index: 4;
  top: 50%;
  left: 10%;
  right: 10%;
  width: 60%;
  align-items: flex-start;
  text-align: start;
}
.events .carousel-caption h2 {
  color: white;
  text-transform: uppercase;
  font-size: 27px;
}
.events .carousel-caption p {
  color: rgb(218, 218, 218) f;
}
.events .carousel-caption h6 {
  color: #E2963C;
  font-size: 1.3rem;
}
@media screen and (max-width: 700px) {
  .events .carousel-caption {
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  .events .carousel-caption {
    width: 90%;
  }
}
.events img {
  filter: brightness(40%);
}

.timeline {
  display: flex;
  justify-content: space-between;
}
.timeline > div {
  width: 50%;
}
@media screen and (max-width: 700px) {
  .timeline {
    flex-direction: column;
  }
  .timeline > div {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .timeline {
    flex-direction: column;
  }
  .timeline > div {
    width: 100%;
  }
}

.left, .right {
  color: white;
  position: relative;
  background-position: center;
  background-size: cover;
}
.left h6, .right h6 {
  color: #E2963C;
}

@media screen and (max-width: 700px) {
  .left {
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.ProductHeader {
  background-color: rgb(39, 90, 83);
  height: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  padding-left: 10%;
  flex-direction: column;
}

.products .item {
  height: 400px;
  position: relative;
  text-align: center;
  transition: 0.5s;
  text-transform: uppercase;
}
.products .item .shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  top: 0%;
  color: white;
  position: absolute;
}
.products .item .shadow svg {
  transform: rotateX(90deg);
  transition: 0.5s;
  font-size: 40px;
}
.products .item:hover .shadow {
  opacity: 0.7;
}
.products .item:hover .shadow svg {
  transform: rotateX(0deg);
}
.products .item .itemimg {
  height: 300px;
  background-position: center;
  background-size: cover;
}
.products .item h1 {
  margin-top: 5px;
  font-size: 22px;
}

.aboutus {
  background-color: #dbdbdb;
  position: relative;
}
.aboutus .flex {
  align-items: center;
  position: absolute;
  height: 100%;
  width: 90%;
  left: 5%;
  right: 5%;
}
.aboutus .flex > div {
  width: 55%;
}
.aboutus .flex h1 {
  color: rgb(27, 75, 68);
}
.aboutus .flex p {
  color: rgb(0, 0, 0);
}
.aboutus .img {
  width: 40%;
}
@media screen and (max-width: 700px) {
  .aboutus .flex {
    overflow: visible;
    top: 10%;
  }
  .aboutus .flex > div {
    width: 100%;
  }
  .aboutus .img {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .aboutus .flex {
    top: 10%;
  }
  .aboutus .flex > div {
    width: 100%;
  }
  .aboutus .img {
    width: 100%;
  }
}

.aboutussection {
  width: 80%;
  margin: 200px auto;
}
.aboutussection h1 {
  color: rgb(27, 75, 68);
  width: 90%;
  font-weight: 400;
}
.aboutussection p {
  color: rgb(0, 0, 0);
  width: 70%;
}
@media screen and (max-width: 700px) {
  .aboutussection {
    margin: 400px auto 200px auto;
  }
}
@media screen and (max-width: 480px) {
  .aboutussection {
    margin: 500px auto 200px auto;
  }
}
@media screen and (max-width: 400px) {
  .aboutussection {
    margin: 600px auto 200px auto;
  }
}

.TopNavbar {
  position: absolute;
  z-index: 5;
  top: 0px;
  width: 100%;
  background-color: rgb(17, 41, 38);
  justify-content: space-around;
}
.TopNavbar .container {
  align-items: center;
}
.TopNavbar .tag {
  color: #ffffff;
  text-decoration: none;
  margin: 5px;
  font-weight: 100;
}
.TopNavbar .btn {
  color: #ffffff;
  border-radius: 0px;
  font-weight: 100;
}
.TopNavbar .btn::before {
  background: #E2963C;
}
@media screen and (max-width: 700px) {
  .TopNavbar {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .TopNavbar {
    display: none;
  }
}

.contact .contactusheader {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.contact .contactusheader .social {
  bottom: -10%;
  height: 100%;
  justify-content: end;
  width: 100%;
  left: 0px;
  right: 0px;
  align-items: normal;
}
.contact .contactusheader .social .flex > div {
  background-color: white;
  color: rgb(27, 75, 68);
  text-align: center;
  display: flex;
  padding: 10px;
  justify-content: center;
  flex-direction: column;
  margin: 5px;
}
.contact .contactusheader .social .flex a {
  color: rgb(27, 75, 68);
}
.contact .contactusheader .social .flex svg {
  font-size: 30px;
  margin: 10px auto;
}
.contact .from {
  margin: 0px;
}
.contact .from .group {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .contactform .flex > div {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .contactform .flex > div {
    width: 100%;
  }
}

.sign {
  color: rgb(27, 75, 68);
  text-align: center;
}

.ftco-section {
  padding: 7em 0;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .blog-entry {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 480px) {
  .blog-entry {
    margin-bottom: 30px;
  }
}
.blog-entry .text {
  position: relative;
  border-top: 0;
  border-radius: 2px;
}
.blog-entry .text .desc {
  width: calc(100% - 100px);
}
.blog-entry .text .heading {
  font-size: 20px;
  margin-bottom: 16px;
}
.blog-entry .text .heading a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}
.blog-entry .text .heading a:hover, .blog-entry .text .heading a:focus, .blog-entry .text .heading a:active {
  color: #E2963C;
}
.blog-entry .meta {
  width: 100px;
  text-align: right;
}
.blog-entry .meta > div {
  display: block;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 15px;
}
.blog-entry .meta > div a {
  text-decoration: none;
  color: #b3b3b3;
  font-size: 13px;
}
.blog-entry .meta > div a:hover {
  color: #cccccc;
}

.block-20 {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 400px;
  position: relative;
  display: block;
}/*# sourceMappingURL=main.css.map */