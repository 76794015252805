$Navbackground-color: rgb(39, 90, 83);
$color: rgb(27, 75, 68);
$maincolor: rgb(17, 41, 38);
$white: #ffffff;
$white2:rgb(218, 218, 218)f;
$whiteop: rgb(0 ,0 ,0 , 1);
$scandry:#E2963C;
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$small: 480px;
$medium: 700px;
$heightord:50px
