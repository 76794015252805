@import 'color_theme';
// @import 'hover';
@font-face {
  font-family: 'Frutiger';
  src: url('../font/PTSerif-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Frutiger_bold';
  src: url('../font/PTSerif-Regular.ttf') format('truetype');
}

@import "hover.css/css/hover.css";
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
h1,h2,h6{
text-transform: uppercase;
font-weight: 400;
  // font-family: 'Frutiger_bold', sans-serif; // Specify the font family for LTR direction
}
p,a{
  // font-family: 'Frutiger', sans-serif; // Specify the font family for LTR direction
  // font-weight: 300;
  letter-spacing: 1px;
}
.maincolor{
  color: $color;
}
.show{
  background-color: $white;

  @media screen and (max-width: $medium) {
    padding: 10px;
    height: 100%;
  }
  @media screen and (max-width: $small) {
    padding: 10px;
    height: 100%;
  }
}
  body {
    // font-family:  "Frutiger";
    overflow-x: hidden;
  }
  .mr-200{
    margin: 100px auto;
  }
  .mt-200{
    margin-top: 100px;
  }
  .mr-100{
    margin: 100px auto;
  }
  .mt-30{
    margin-top: 30px;
  }
  .mr-30{
    margin: 30px auto;
  }
  h6{
    color: black;
    font-weight: 600;
  }
  .shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    // opacity: 0.4;
    z-index: 2;
  }
  .navbar-toggler{
    background-color: white;
  }
  .read,.question{
    background-color: $color;
    color: white;
    border: 2px solid $color;
    text-decoration: none;
    padding: 10px 20px;
    transition: 0.5s ease;
  }
  .read:hover ,.question {
    color: $color;
    background-color: white;
  }
  .question:hover{
    background-color: $color;
    color: white;
  }
  .flex-1{
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 67%;
    p{
      line-height: 30px;
      font-size: 25px;
      font-weight: 100;

    }

  }
  .flex-2{
    justify-content: space-between;
    >div{
      width: 48%;

    }
    
    
  }
  .flex-3{
    justify-content: space-evenly;
    >div{
      width: 32%;

    }
  }
  .flex-4{
    justify-content: space-evenly;
    >div{
      width: 24%;

    }
  }
  .aline-item{
    align-items: center;
    p{
   color:  $whiteop;
    }
    min-height: 300px;
  }
  .flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    .wid-2{
      width: 65%;
    }
    @media screen and (max-width: $medium) {
      >div,img,.wid-2{
        width: 100%;
      }
    }
    @media screen and (max-width: $small) {
      >div,img,.wid-2{
        width: 100%;
      }
    }
  
  .item{
    background-size: cover;
    position: relative;
    background-position: center;
    margin: 10px auto;
    overflow: hidden;
    height: 300px;
    .text{
      position: absolute;
      z-index: 3;
      top: 80%;
      color: white;
      display: flex;
      padding: 0px 22px;
      flex-direction: column;
      justify-content: space-evenly;
      transition: 0.5s ease-in;
      h2{
        font-size: 20px;
        font-weight: 400;
      }
      p{
        transform: rotateX(90deg);
        transition: 0.6s ease-in;
        font-size: 12px;
        opacity: 0.7;

      }

    }
    h1{
      color: $color;
      font-size: 30px;
      margin-bottom: 20px;

    }
    .shadow{

      background-color: $Navbackground-color;
      opacity: 0;
      transition: 0.5s ease-in;
    }
    &:hover{
      .shadow{

        background-color: $Navbackground-color;
        opacity: 1;
      }
      .text{
        top: 20%;
        p{
          transform: rotateX(0deg);
        }
      }
    }
  }
  }
  .header{
    width: 100%;
    text-align: center;
    margin: 7px auto;
    h1{
      color: $color;
      font-size: 40px;
      margin: 20px auto;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
  .social{
    position: absolute;
    color: $white;
    z-index: 3;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 10%;
    right: 10%;
    h1{
      font-weight: bolder;
      font-size: 60px;   
      text-align: center;
      color: $maincolor;
      text-transform: capitalize;
    
    }
    p{
      text-align: center;

    }
    a{
      text-decoration: none;
      color: $white;
      font-size: 20px;
      text-transform: capitalize;
      text-align: center;
      svg{
        background-color: $white;
        color:  $Navbackground-color;
        padding: 10px;
        border-radius: 50%;
      }
    }
    .read{
      // padding: 20px 30px;
    }
    @media screen and (min-width: $bp-medium) {
      left: 20%;
      width: 60%;
      right: 20%;
      h1{
      font-size: 35px;   
     }
     p{
      font-size: 20px;   
    }
    }
    @media screen and (max-width: $medium) {
      left: 10%;
      right: 10%;
      h1{
      font-size: 40px;   
     }
     p{
      font-size: 20px;   
    }
    }
    @media screen and (max-width: $small) {
      left: 10%;
      right: 10%;
      h1{
        font-size: 30px;   
      }
      p{
        font-size: 12px;   
      }
    }

  }
.navbar{
 
  position: absolute;
  z-index: 150000000000000;
  top: 30px;
  width: 100%;
  .dropdown{
    button{
      &::after{
        font-size: large;
      }
      &:hover{
        background-color: transparent;
      }
      
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
      background-color: transparent;
    }
  }
  .nav-link{
   color: $white;
   transition: 0.5s ease;
   font-size: 13px;
   margin: 0px 5px;
   &:hover{
    color: $Navbackground-color;
   }
   @media screen and (max-width: $medium) {
    color: $color;
   }
   @media screen and (max-width: $small) {
    color: $color;
   }
  }
  img{
    width: 141px;
    height: 50px;
  }
  @media screen and (max-width: $medium) {
    top: 0px;

  }
  @media screen and (max-width: $small) {
    top: 0px;

  
  }
}
.sticky{
  position: fixed;
  background-color: $white;
  top: 0px;
  .nav-link{
    color: $color;
    &:hover{
      color: $scandry;
     opacity: 0.8;

    }
    @media screen and (max-width: $medium) {
       
    }
    @media screen and (max-width: $small) {
     
    
    }
}}
.HomeBanner{
  width: 100%;
  height: 700px;
  position: relative;
  video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


}
.img,.img2{
  background-position: 0% 25%;
  box-shadow: 0px 0px 5px #d1d1d1, -2px -2px 30px #d1d1d1;
  background-size: cover;
  // background-attachment: fixed;

}
.img2{
  background-position: 0% 42%;

}
.abouttext{
  padding: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .img2{
    height: 50%;
    margin-bottom: 5%;
  }
  h1{
    color: $color;
    font-weight: 400;
  }
  p{
    color: $whiteop;
    margin: 20px auto;
    font-size: 15px;

  }
  a{
    text-decoration: none;

  }

}
.swiper{
  margin-top: 30px;
  .swiper-slide{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 150px;
    height: 80px;
  }
}
.from{

  text-align: center;
  margin: 100px auto;

  .group 			  { 
      position:relative; 

      margin:45px auto; 
      width: 60%;
      @media screen and (max-width: $medium) {
        width: 100%;
      }
      @media screen and (max-width: $small) {
        width: 100%;
      
    }
    }
    .highlight {
      position:absolute;
      height:60%; 
      width:100px; 
      top:25%; 
      left:0;
      pointer-events:none;
      opacity:0.5;
    }
    
    /* active state */
    input:focus ~ .highlight {
      -webkit-animation:inputHighlighter 0.3s ease;
      -moz-animation:inputHighlighter 0.3s ease;
      animation:inputHighlighter 0.3s ease;
    }
    
    /* ANIMATIONS ================ */
    @-webkit-keyframes inputHighlighter {
      from { background:#5264AE; }
      to 	{ width:0; background:transparent; }
    }
    @-moz-keyframes inputHighlighter {
      from { background:#5264AE; }
      to 	{ width:0; background:transparent; }
    }
    @keyframes inputHighlighter {
      from { background:#5264AE; }
      to 	{ width:0; background:transparent; }
    }
    
input 				{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #757575;
}
input:focus 		{ outline:none; }

/* LABEL ======================================= */
label 				 {
  color:#999; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:$scandry;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:100%; }
.bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:$scandry; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}
@media (min-width: 400px) and (max-width: 700px) {
  .group{
      width: 100%;
  }
  .bar{
      width: 100%;

  }
  input{
      width: 100%;
  }

}
}
.send{
  background-color:$color ;
  padding: 15px 50px ;
  border: 0px;
  transition: 0.5s;
  &:hover{
      background-color:$scandry ;
  }
}
.Footer{
  // background-color: $maincolor;
  overflow: hidden;
  color: $white;
  padding: 20px ;
  p{
    color: black;
    margin: 20px auto;
    opacity: 0.8;
    font-size: 14px;
  }
.container{
  margin:  40px auto;
}
a{
  font-size: 15px;

  text-decoration: none;
  color: black;
  transition: 0.4s ease-in-out;
  &:hover{
    color: $scandry;
  }
}
  .socialfooter{
    
      a{
          font-size: 25px;
          width: 25px;
          margin-right: 20px;
          text-decoration: none;
          color: $scandry;
          transition: 0.5s ease-in-out;
          &:hover{
            color: $color;
          }
      }
  }


  .Home{
      text-decoration: none;
      // color: $white;
  }
  img{
      height: $heightord;
      width:141px;
      @media screen and (max-width: $medium) {
        height: 50px;
        width: (30*3.85)px;

      }
      @media screen and (max-width: $small) {
       
        height: 50px;
        width: 170px;
    }
  }
  h1{
    font-size: 20px;  
    text-transform: uppercase;
    color: $color;
  }
 
}
.banner{
  background-position: center;
  background-size: cover;
  height: 600px;
  position: relative;
  .section{
    color: rgb(255, 255, 255);
    position: absolute;
    bottom: 10%;
    left: 5%;
    right: 5%;
    width: 50%;
    z-index: 3;
    h4{
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 900;
    }
    p{
      color: rgba(255, 255, 255, 0.74);
      font-size: 17px;
    }
    .text{
      color: white;
      margin: 5px 5px 10px 5px;
      color: $scandry;
      font-weight: 700;
    }
    a{
      color:  rgba(255, 255, 255, 0.514);
      transition: 0.5s ease;
      text-decoration: none;
      font-weight: 700;

      &:hover{
        color: white;
      }

    }
    @media screen and (max-width: $medium) {
      width: 100%;
    }
    @media screen and (max-width: $small) {
      width: 100%;
    
    }
  }
}
.swiper-button-next,.swiper-button-prev{
  background-color: $scandry !important;
  color: white !important; 
  padding: 25px !important;
  border-radius: 50% !important;
 &::after{
  text-align: center !important;
  font-size: 20px !important;
 }
}
.swiper-pagination-bullet-active{
  background-color: $scandry !important;
}
.data img{
  width: 100%;
}
.recode{
  justify-content: space-between;
}
.cut .img2{
  margin-top: 10%;
}
.homecut{
  min-height: 400px;
}
.homecut:nth-child(odd) {
  .abouttext{
    order: 1;
  }
  .img{
    order: 2;
  }
}

.homecut:nth-child(even) {
  .abouttext{
    order: 2;
  }
  .img{
    order: 1;
  }
}
.carousel-control-prev,.carousel-control-next,.carousel-caption,.carousel-indicators{
  z-index: 5;
}
.events{

.carousel-caption{
  z-index: 4;

    top: 50%;
  left: 10%;
  right: 10%;
    width: 60%;
    align-items: flex-start;
    text-align: start;
   h2{
    color: white;
    text-transform: uppercase;
    font-size: 27px;
   }
   p{
    color: $white2;

   }
   h6{
    color: $scandry;
    font-size: 1.3rem;
   }
   @media screen and (max-width: $medium) {
    width: 90%;
   }
   @media screen and (max-width: $small) {
    width: 90%;
   
   }
   
  }
  img{
    filter:brightness(40%)

  }

}
.timeline{
  display: flex;
  justify-content: space-between;
  >div{
    width: 50%;
   
  }
  @media screen and (max-width: $medium) {
    flex-direction: column;
    >div{
      width: 100%;
     
    }
    }
  @media screen and (max-width: $small) {
    flex-direction: column;

    >div{
      width: 100%;
     
    }
  }
}
.left,.right{
  color: white;
  position: relative;
  background-position: center;
  background-size: cover;
 
  h6{
    color: $scandry;
  }
}
.left{
  @media screen and (max-width: $medium) {
    background-size: contain;
    background-repeat: no-repeat;

    }
 
}
.ProductHeader{
  background-color: $Navbackground-color;
  height: 700px;
  position: relative;
  display: flex;
justify-content: center;
padding-left: 10%;  
  flex-direction: column;
  
}
.products{
  .item{
    height: 400px;
    position: relative;
    text-align: center;
    transition: 0.5s;
    text-transform: uppercase;

    .shadow{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      top: 0%;
      color: white;
     
      position: absolute;
      svg{
        transform: rotateX(90deg);
        transition: 0.5s;
        font-size: 40px;
      }

    }
    &:hover{
      .shadow{
        svg{
          transform: rotateX(0deg);
        }
        opacity: 0.7;
        
      }
    }
    .itemimg{
      height: 300px;
      // position: ;
      background-position: center;
      background-size: cover;
    }
    h1{
      margin-top: 5px;
      font-size: 22px;
    }
  }
}
.aboutus{
  background-color: #dbdbdb;
  position: relative;
  .flex{
    align-items: center;
    position: absolute;
    height: 100%;
    width: 90%;
    left: 5%;
    right: 5%;
    >div{
      width: 55%;
    }
    h6{
      // color: white;
    }
    h1{
      color: $color;
    }
    p{
      color: $whiteop;
    }
  }
  .img{
    width: 40%;
    
  }
  @media screen and (max-width: $medium) {
    .flex{
      overflow: visible;
      top: 10%;
      >div{
        width: 100%;
      }
    }
    .img{
      width: 100%;
    }
  }
  @media screen and (max-width: $small) {
    .flex{
      top: 10%;
      >div{
        width: 100%;
      }
    }
    .img{
      width: 100%;
    }
  }
}
.aboutussection{
  width: 80%;
  margin: 200px auto;
  h6{
    // color: white;
  }
  h1{
    color: $color;
    width: 90%;
    font-weight: 400;
  }
  p{
    color: $whiteop;
    width: 70%;
  }
  @media screen and (max-width: $medium) {
    margin: 400px auto 200px auto;

  }

  @media screen and (max-width: $small) {
    margin: 500px auto 200px auto;

  }
  @media screen and (max-width: 400px) {
    margin: 600px auto 200px auto;

  }
}
.TopNavbar{
  position: absolute;
  z-index: 5;
  top: 0px;
  width: 100%;
  background-color: $maincolor;
  // padding: 5px;  
  justify-content: space-around;
  .container{
    align-items: center;
  }
  .tag{
    color: $white;
    text-decoration: none;
    margin: 5px;
    font-weight: 100;

  }
  .btn{
    color: $white;
    border-radius: 0px;
    font-weight: 100;

    &::before{
      background: $scandry;
    }
  }
  @media screen and (max-width: $medium) {
       display: none;
  }
  @media screen and (max-width: $small) {
    display: none;
   
  
}
}
.contact{
  .contactusheader{
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .social{
      bottom: -10%;
      height: 100%;
      justify-content: end;
      width: 100%;
      left: 0px;
      right: 0px;
      align-items: normal;
      .flex{
        >div{
          background-color: white;
          color: $color;
          text-align: center;
          display: flex;
          padding: 10px;
          justify-content: center;
          flex-direction: column;
          margin: 5px;
      
        }
        a{
          color: $color;
        }
        svg{
          font-size: 30px;
          margin: 10px auto;
        }
      }
    }
    
  }
  .from {
    margin: 0px;
    .group{
      width: 100%;
    }
  }
}
.contactform{
  .flex{
    >div{
      @media screen and (max-width: $medium) {
        width: 100%;
   }
   @media screen and (max-width: $small) {
    width: 100%;

    
   
 }
    }
  }
}
.sign{
  color: $color;
  text-align: center;
}
.ftco-section {
	padding: 7em 0;
	position: relative;
	overflow: hidden;
}
.blog-entry{
	@media screen and (max-width: $medium){
		margin-bottom: 30px;
	}
	@media screen and (max-width: $small){
		margin-bottom: 30px;
	}
	.text {
		position: relative;
		border-top: 0;
		border-radius: 2px;
		.desc{
			width: calc(100% - 100px);
		}
		.heading {
			font-size: 20px;
			margin-bottom: 16px;
			a {
				color: $whiteop;
        text-decoration: none;
				&:hover, &:focus, &:active {
					color: $scandry;
				}
			}
		}

	
	}
	.meta {
		width: 100px;
		text-align: right;
		> div {
			display: block;
			margin-right: 5px;
			margin-bottom: 0px;
			font-size: 15px;
      
			a {
        text-decoration: none;
				color: lighten($whiteop, 70%);
				font-size: 13px;
				&:hover {
					color: lighten($whiteop, 80%);
				}
			}
		}
	}
}
.block-20 {
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 400px;
	position: relative;
	display: block;
}
